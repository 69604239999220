import Img from 'gatsby-image';
import React from 'react';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Paragraph } from '../Paragraph';
import { SmallSubtitle } from '../SmallSubtitle';

export const AvatarTestimonial = ({ avatar, content, cite }) => {
    return (
        <Flex>
            {avatar && (
                <Box h="40px" w="40px" minW="40px" minH="40px" mr="spacing-lg">
                    <Img
                        fixed={avatar}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
            )}
            <Box>
                {content && (
                    <Paragraph color="boldText" fontSize="smallBody">
                        {content}
                    </Paragraph>
                )}
                {cite && <SmallSubtitle mt="spacing">{cite}</SmallSubtitle>}
            </Box>
        </Flex>
    );
};
