import React from 'react';
import { Box } from '../Box';
import { LandingFeatureGrid } from '../Landing/LandingFeatureGrid';

const $methodListItems = [
    {
        heading: 'strategize',
        content:
            'A comprehensive consultation and strategy outline developed for your community',
        color: '#01A8FF',
        href: '/methods/strategize',
    },
    {
        heading: 'build',
        content: 'Execution on the action plan laid out during strategy',
        color: '#5BCD9F',
        href: '/methods/build',
    },
    {
        heading: 'manage',
        content:
            'Our community managers handling the day to day details for you',
        color: '#EB4E4B',
        href: '/methods/manage',
    },
    {
        heading: 'maintain',
        content:
            'On a retainer basis, we’ll regularly update and upgrade your community',
        color: '#E251AB',
        href: '/methods/maintain',
    },
    {
        heading: 'app',
        content: 'A native experience for your community',
        color: '#F0932B',
        href: '/methods/app',
    },
    {
        heading: 'metrics',
        content: 'Detailed analytics and KPIs in a report tailored to you',
        color: '#BE2EDD',
        href: '/methods/metrics',
    },
    {
        heading: 'resources',
        content:
            'Product line that includes a theme framework, software bridge, and add-ons',
        color: '#F9CA23',
        href: '/methods/resources',
    },
    {
        heading: 'live events',
        content:
            'Stream to your community or audience from the app or separately',
        color: '#00C8D5',
        href: '/methods/streaming',
    },
];

export const Methods = props => {
    return (
        <Box as="nav">
            <LandingFeatureGrid
                title="Methods"
                subtitle="The primary ways we help communities"
                showContact={false}
                items={$methodListItems}
                {...props}
            />
        </Box>
    );
};
