import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Flex } from 'src/components/Flex';
import { MediaSection } from 'src/components/MediaSection';
import { BoxGrid } from 'src/components/Methods/app/BoxGrid';
import { Methods } from 'src/components/Methods/Methods';
import { MethodsCTA } from 'src/components/Methods/MethodsCTA';
import { CollaborateSection } from 'src/components/Methods/strategize/CollaborateSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { PrinciplesSection } from 'src/homepage/Principles';

const subnav = [
    {
        id: 'community-experts',
        label: 'Community Experts',
    },
    {
        id: 'process',
        label: 'Process',
    },
];

export default function strategize({ data }) {
    const flairColor = '#01A8FF';
    return (
        <Layout
            pageTitleFull="A thorough online community strategy with Audentio"
            siteDescription="Whether you’re creating an online community or growing one, we’ll craft an actionable strategy for your goals."
            contactForm
            methodSubNavIcon="/methods/strategize.svg"
            methodSubNavColor={flairColor}
            subnavTitle="Strategize"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="Comprehensive strategy outlined by a community expert"
                video="/videos/methods/strategize/hero.mp4"
                videoPoster="/videos/methods/strategize/hero-poster.png"
                videoId="methods-strategize-hero"
                subtitle="Our team will deliver a thoughtful and thorough strategy document based on your goals, potential ROI, and a deep dive into your community's current state."
            />

            <CollaborateSection id={subnav[0].id} data={data} />

            <Section
                vertical="lg"
                pb={[null, null, 0]}
                mb={[null, null, -150]}
                wrap
                bg="secondaryBackground"
            >
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderBottom="1px"
                    borderColor="border"
                    pb={['spacing-lg', '40px', 'spacing-xl']}
                />

                {/* This isn't really a media section, but the component allows for a custom content section, and helps us stick with a consistent layout and structure */}
                <MediaSection
                    title="We take your community through our tried-and-true strategy"
                    flipped
                    customMedia={
                        <Flex>
                            <Paragraph color="bodyText" maxW={525}>
                                Our team of community experts, including
                                designers, developers, and community managers,
                                can help your community grow with an emphasis on
                                better engagement and more monetization
                                opportunities.
                            </Paragraph>
                        </Flex>
                    }
                />

                <BoxGrid
                    id={subnav[1].id}
                    steps={[
                        {
                            number: '01',
                            heading: 'Learn',
                            content:
                                "Let's meet, discuss your goals, critical success factors, and the most important aspects of your community.",
                        },
                        {
                            number: '02',
                            heading: 'Discover',
                            content:
                                'Our team spends the necessary time to objectively audit and assess the state of the community.',
                        },
                        {
                            number: '03',
                            heading: 'Plan',
                            content:
                                'We create a realistic and achievable strategy document, broken down into an actionable roadmap that aligns with your timeline and budget.',
                        },
                    ]}
                />
            </Section>

            <MethodsCTA
                title="Save your time. Get higher returns. Have the community you deserve."
                flairColor={flairColor}
                bg="primaryBackground"
                // custom top padding that works in conjunction with the previous section margin to 'offset and overlap' the grid with the section below
                pt={['40px', 'spacing-xl', `${80 + 150}px`]}
            />

            <PrinciplesSection />

            <Methods
                bg={{
                    _: 'primaryBackground',
                    lg: 'secondaryBackground',
                }}
                pt={{
                    _: null,
                    lg: 0,
                }}
            />
        </Layout>
    );
}

export const query = graphql`
    query strategizeQuery {
        arnoldkim: file(
            relativePath: { eq: "images/strategize/arnold-kim-macrumors.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        mike: file(relativePath: { eq: "images/strategize/mike.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
