import Img from 'gatsby-image';
import React from 'react';
import { AnimatedContainer } from 'src/components/AnimatedContainer';
import { AvatarHighlight } from 'src/components/AvatarHighlight';
import { Box } from 'src/components/Box';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight,
} from 'src/components/GridSection';
import { Heading } from 'src/components/Heading';
import { IO } from 'src/components/IO';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider, useTheme } from 'src/components/ThemeProvider';

// custom section to match the spacing of the hero while using custom contents
export const CollaborateSection = ({ data, ...props }) => {
    const { sizes } = useTheme();
    return (
        <Section
            vertical="lg"
            py={[0, 0, 0]}
            wrap
            maxWidth="containers.header"
            {...props}
        >
            <GridSection
                alignItems={{
                    _: 'flex-start',
                    md: 'center',
                }}
                direction={{
                    _: 'column',
                    md: 'row',
                }}
            >
                <GridSectionLeft
                    px={0}
                    mt={0}
                    maxWidth={{ _: '100%', md: '50%', xl: '40%' }}
                    pl={{
                        _: 0,
                        xl: `calc((${sizes.containers.header} - ${sizes.containers.content}) / 2)`,
                    }}
                    py={['40px', 'spacing-xl', '80px']}
                >
                    <AvatarHighlight
                        bg="#fff"
                        vertical="0"
                        avatar={data?.arnoldkim.childImageSharp.fixed}
                        content="Audentio has been a pleasure to work with. Not only
                        is their work technically excellent, but they are
                        also easy to communicate with and responsive to our
                        needs and goals. We have been very happy with their
                        work."
                        cite="Arnold Kim, Founder and Editor of MacRumors"
                        title="Collaborate and discuss your goals with an
                        expert"
                        border
                        borderProps={{
                            borderBottom: '1px',
                            borderColor: 'border',
                            pb: 'spacing-lg',
                            mb: 'spacing-lg',
                        }}
                    />
                </GridSectionLeft>

                <GridSectionRight pl={{ _: 0, md: 20, lg: 32 }}>
                    <Box
                        width="100%"
                        d="flex"
                        alignItems={{
                            xl: 'flex-end',
                        }}
                    >
                        <Standout
                            img={data.mike.childImageSharp.fluid}
                            title="Mike has been in the community space for 10+ years"
                            description="Originally having started his own forums, Mike has since used his passion for communities to help small niche communities, industry leaders, and some of the largest communities on the internet."
                            alt="Mike Creuzer, owner of Audentio and community strategist."
                        />
                    </Box>
                </GridSectionRight>
            </GridSection>
        </Section>
    );
};

const Standout = ({ img, title, description, alt }) => {
    return (
        <ThemeProvider defaultMode="dark">
            <Box
                bg="primaryBackground"
                w="100%"
                maxW={{
                    _: 'unset',
                    xl: 450,
                }}
                zIndex={10}
                mt={{
                    _: 0,
                    xl: '-2.5rem',
                }}
            >
                <IO>
                    {({ isVisible }) => (
                        <AnimatedContainer
                            isAnimated={isVisible}
                            offset={{
                                initial: {
                                    x: 0,
                                },
                                final: {
                                    x: -50,
                                },
                            }}
                        >
                            <Box h={200}>
                                <Img
                                    style={{
                                        height: '100%',
                                        width: 'auto',
                                    }}
                                    imgStyle={{
                                        width: 'auto',
                                        height: '100%',
                                    }}
                                    fluid={img}
                                    alt={alt}
                                />
                            </Box>
                        </AnimatedContainer>
                    )}
                </IO>

                <Box p="spacing-xl">
                    <Heading kind="h6" pb="spacing" mb={0} color="#A7E8E0">
                        {title}
                    </Heading>

                    <Paragraph color="boldText">{description}</Paragraph>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
