import React from 'react';
import { AvatarTestimonial } from '../AvatarTestimonial/AvatarTestimonial';
import { Box } from '../Box';
import { MediaSection } from '../MediaSection/MediaSection';

interface AvatarHighlightProps {
    bg?: string;
    title: string;
    subtitle?: string;
    content: string;
    avatar: string;
    image?: string;
    cite: string;
    border?: boolean;
    borderProps?: {};
    vertical?: string;
    wrap?: boolean;
}
export const AvatarHighlight = ({
    image,
    avatar,
    content,
    cite,
    title,
    subtitle,
    border,
    borderProps,
    bg,
    vertical,
}: AvatarHighlightProps) => {
    return (
        <MediaSection
            vertical={vertical}
            bg={bg}
            image={image}
            subtitle={subtitle}
            title={title}
            flipped
        >
            {border && <Box {...borderProps}></Box>}
            <AvatarTestimonial cite={cite} avatar={avatar} content={content} />
        </MediaSection>
    );
};
